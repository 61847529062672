export const useMenuRefinery = () => {
  const menu = [
    { title: 'Dashboard', to: '/refinery/dashboard' },
    { title: 'Report', to: '/refinery/report/ullage' },
    { title: 'Planner', to: '/refinery/planner/shipment' },
    { title: 'Purchase Request', to: '/refinery/purchaserequest' },
    { title: 'Purchaser', to: '/refinery/purchaser/list' },
    { title: 'Factory Manager', to: '/refinery/factorymanager/list' },
    { title: 'COSS', to: '/refinery/coss/' }
  ]
  return menu
}
